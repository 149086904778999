<template>
  <v-card>
    <v-card-title class="text-h5" primary-title>
      <h6 class="text-h6">
        {{ id ? $trans("edit_resource") : $trans("add_resource") }}
      </h6>
    </v-card-title>
    <v-divider />
    <v-form ref="form_resource" @submit.prevent="handleSave">
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="7">
              <v-text-field
                v-model="name"
                hide-details="auto"
                :rules="[rules.required, rules.maxChars(30)]"
                :label="$trans('resource_name')"
                outlined
              />
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="quantity"
                :label="$trans('quantity_pcs')"
                hide-details="auto"
                :rules="[
                  rules.required,
                  rules.isInteger,
                  rules.minNumber(quantity, 1),
                  rules.maxNumber,
                ]"
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="light" text :disabled="isLoading" @click="$emit('close')">
          {{ $trans("cancel") }}
        </v-btn>
        <v-btn
          :disabled="isLoading || !name || !quantity"
          color="primary"
          text
          type="submit"
        >
          <span v-if="id">{{ $trans("save") }}</span>
          <span v-else>{{ $trans("add") }}</span>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import {
  isInteger,
  maxChars,
  maxNumber,
  minNumber,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "ResourceDialog",
  mixins: [sharedActions],
  data() {
    return {
      isLoading: false,
      name: null,
      quantity: null,
      id: null,
      resourceCopy: null,
      rules: {
        required,
        maxChars,
        maxNumber,
        minNumber,
        isInteger,
      },
    };
  },
  watch: {
    "getConfirmDialog.data": function () {
      this.fillForm();
    },
  },
  created() {
    this.fillForm();
  },
  methods: {
    ...mapActions({
      createResource: "resource/create",
      updateResource: "resource/update",
      refreshResources: "resource/refreshResources",
      fetchAllResources: "resource/fetchAll",
    }),
    ...mapMutations({
      setResource: "resource/SET_RESOURCE_ID",
    }),
    fillForm() {
      if (this.getConfirmDialog.data) {
        const resource = this.$helpers.cloneObject(this.getConfirmDialog.data);
        this.resourceCopy = resource;
        this.name = resource.name;
        this.quantity = resource.quantity;
        this.id = resource.id;
      }
    },
    handleSave() {
      if (this.$refs.form_resource.validate()) {
        this.isLoading = true;
        this.$emit("loading", true);
        this.getConfirmDialog.data ? this.update() : this.add();
      }
    },
    async add() {
      try {
        pushEvent("createResource");

        const data = await this.createResource({
          name: this.name,
          quantity: this.quantity,
        });
        if (this.$route.name === "add_category") {
          this.setResource(data.id);
        }
        this.fetchAllResources();
        this.refreshResources();
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
        this.$emit("loading", false);
        this.$emit("close");
      }
    },
    async update() {
      try {
        pushEvent("updateResource");

        await this.updateResource({
          name: this.name,
          quantity: this.quantity,
          id: this.id,
        });

        this.fetchAllResources();
        this.refreshResources();
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
        this.$emit("loading", false);
        this.$emit("close");
      }
    },
  },
};
</script>
